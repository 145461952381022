<div class="row">
  <div class="col">
    <h2 class="text-center mt-3 mb-2">Deine besten Kunden</h2>
  </div>
</div>

<div class="row">
  <div class="col col-xl-10 offset-xl-1">
    <div class="card" style="overflow-x: auto; overflow-y: hidden">
      <table class="table card-table">
        <thead>
        <tr>
          <th colspan="4">
          <span class="time-range-picker" *ngIf="false">
            30 Tage
            <fa-icon [icon]="faSort" style="color: #d8d8d8; padding-left: 5px"></fa-icon>
          </span>
            Übersicht Top Kunden
          </th>
        </tr>
        <tr>
          <th>
            Kunde
          </th>
          <th style="width: 20%">
            Anmeldung
          </th>
          <th style="width: 10%">
            Besuche
          </th>
          <th style="width: 20%">
            Umsatz
          </th>
        </tr>
        </thead>
        <tbody>
        <ng-container *ngIf="bestCustomers && bestCustomers.length == 5">
          <tr *ngFor="let customer of bestCustomers">
            <td>
              <img src="assets/img/trophy.svg" class="line-icon" alt="">
              {{customer.email}}
            </td>
            <td>
              {{customer.created_at | timeAgo}}
            </td>
            <td>
              {{customer.visits}}
            </td>
            <td>
              {{customer.revenue | currency:'EUR'}}
            </td>
          </tr>
        </ng-container>
        <tr *ngIf="!bestCustomers">
          <td colspan="4">
            <div class="text-center" style="height: 200px; line-height: 200px;">Daten werden geladen...</div>
          </td>
        </tr>
        <tr *ngIf="bestCustomers && bestCustomers.length < 5">
          <td colspan="4">
            <div class="text-center" style="height: 200px; line-height: 200px;">Noch keine Daten verfügbar</div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

  </div>
</div>

