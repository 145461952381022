import {Component, Input, OnInit} from '@angular/core';
import {ChartComponent} from '../chart';
import {MerchantService} from '../../../merchant/merchant.service';
import {HttpClient} from '@angular/common/http';

// TODO naming convention
@Component({
  selector: 'horizontalbarchart',
  templateUrl: './horizontalbarchart.component.html',
  styleUrls: ['./horizontalbarchart.component.scss']
})
export class HorizontalbarchartComponent extends ChartComponent implements OnInit {
  @Input() slug;
  @Input() delay;
  barChartOptions = {
    scales: {
      xAxes: [{
        barPercentage: 0.5,
        categoryPercentage: 1,
        beginAtZero: true,
        gridLines: {
          color: '#dddddd',
          drawOnChartArea: false
        }
      }],
      yAxes: [{
        gridLines: {
          color: '#f5f5f5',
          drawOnChartArea: true
        }
      }]
    }
  };

  constructor(protected http: HttpClient,
              protected merchantservice: MerchantService) {
    super(http, merchantservice);
  }
}
