import {Component, Input, OnInit} from '@angular/core';
import {environment} from '../../../environments/environment';
import {ChartData} from './chartdata';
import {MerchantService} from '../../merchant/merchant.service';
import {HttpClient} from '@angular/common/http';
import {first} from 'rxjs/operators';

@Component({
  selector: 'chart',
  template: `
    <canvas baseChart *ngIf="lineChartLabels.length > 0" class="chart"
            [datasets]="lineChartData"
            [labels]="lineChartLabels"
            [options]="lineChartOptions"
            [colors]="lineChartColors"
            [legend]="lineChartLegend"
            [chartType]="lineChartType"></canvas>`,
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements OnInit {
  @Input() slug: string;
  @Input() delay = 0;
  @Input() lineChartType = 'bar';
  @Input() stacked = false;
  context: any = {};

  public lineChartData: Array<any> = [];
  public lineChartLabels: Array<any> = [];
  public lineChartOptions: any = {};
  public lineChartColors: Array<any> = [
    {
      backgroundColor: 'rgba(127,209,239,1)',
      borderColor: 'rgba(127,209,239,1)',
      pointBackgroundColor: 'rgba(0,163,224,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(0,163,224,0.8)'
    },
    {
      backgroundColor: 'rgba(130, 213, 129 ,1)',
      borderColor: 'rgba(130, 213, 129 ,1)',
      pointBackgroundColor: 'rgba(0,145,199,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(0,145,199,0.8)'
    },
    {
      backgroundColor: 'rgba(0,127,174,0.2)',
      borderColor: 'rgba(0,127,174,1)',
      pointBackgroundColor: 'rgba(0,127,174,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(0,127,174,0.8)'
    },
    {
      backgroundColor: 'rgba(0,108,149,0.2)',
      borderColor: 'rgba(0,108,149,1)',
      pointBackgroundColor: 'rgba(0,108,149,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(0,108,149,0.8)'
    }
  ];
  public lineChartLegend = false;
  n: number;

  constructor(protected http: HttpClient,
              protected merchantService: MerchantService) {
  }

  ngOnInit() {
    this.merchantService.currentContext.subscribe(context => {
      this.context = context;
      this.loadData();
    });
  }

  loadData() {
    setTimeout(() => {
      this.reload();
    }, this.delay);
  }

  private reload() {
    this.http.get(environment.api_base_url + 'stats/' + this.slug).pipe(first()).subscribe((res) => {
      // TODO: fix next 3 lines
      const data = res;
      this.n = (res as any).n;
      const chartData: ChartData = new ChartData((data as any).data);
      this.lineChartData = chartData.getData();
      this.lineChartLabels = chartData.getLabels();
      this.lineChartOptions = chartData.getOptions();
      if (this.stacked === true) {
        this.lineChartOptions.scales.xAxes[0].stacked = true;
        this.lineChartOptions.scales.yAxes[0].stacked = true;
      }
    });
  }

}
