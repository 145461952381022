<app-addon-promotion-recurring [addonType]="addonTypes.OBTAIN_REVIEW"></app-addon-promotion-recurring>
<!--<app-advent-calendar-promotion [posExperienceType]="'advent_calendar'"></app-advent-calendar-promotion>-->
<div class="container card-primary" *ngIf="false">
  <p>
    <a [routerLink]="['/pos_experiences', 'advent_calendar']">
      Begeistere deine Kunden mit dem Mankido Adventskalender: Jetzt in unter 5 Minuten aktivieren!
    </a>
  </p>
</div>

<h1 class="text-center mt-5 mb-0">Dashboard</h1>

<cards class="mt-1 mb-1" style="flex-shrink: 0;">
  <stat-number [title]="'Mankido Nutzer'" [slug]="'memberships'" class="card card-block text-center"></stat-number>
  <stat-number [title]="'Transaktionen'" [slug]="'transactions'" class="card card-block text-center"></stat-number>
  <stat-number [title]="'Umsatz'" [slug]="'transaction_volume'" class="card card-block text-center euro-sign"></stat-number>
  <stat-number [title]="'Google Bewertungen'" [slug]="'total_obtained_reviews'" class="card card-block text-center"></stat-number>
</cards>

<cards *ngIf="betaService.beta">
  <stat-number [title]="'Neuanmeldungen'" [slug]="'signups'" class="card card-block text-center"></stat-number>
  <stat-number [title]="'Aus dem Netzwerk'" [slug]="'users_from_network'" class="card card-block text-center"></stat-number>
  <stat-number [title]="'Email bestätigt'" [slug]="'confirmed_users'" class="card card-block text-center"></stat-number>
  <stat-number [title]="'App Nutzer'" [slug]="'app_users'" class="card card-block text-center"></stat-number>
  <stat-number [title]="'Personalisiert'" [slug]="'personalized_users'" class="card card-block text-center"></stat-number>
  <stat-number [title]="'Anonyme User'" [slug]="'anonymous_users'" class="card card-block text-center"></stat-number>
  <stat-number [title]="'Karte > App konvertiert'" [slug]="'deanonymized_users'" class="card card-block text-center"></stat-number>
</cards>

<main>
  <app-dashboard-automations *ngIf="!context.partner_id"></app-dashboard-automations>

  <app-segmentation></app-segmentation>

  <app-bestcustomers></app-bestcustomers>

  <div class="row mb-3">
    <div class="col col-xl-10 offset-xl-1">
      <h2 class="text-center mt-3 mb-2">Demographie</h2>
      <div class="row">
        <div class="col-md-6 mb-1 mb-md-0">
          <div class="card" style="display: flex; padding-bottom: 2rem;">
            <h4 class="text-center mt-3 mb-2">Geschlechtsverteilung</h4>
            <genderchart [slug]="'gender_distribution'" [delay]="500"></genderchart>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card" style="display: flex; padding-bottom: 2rem; align-items: center;">
            <h4 class="text-center mt-3 mb-2">Altersverteilung</h4>
            <horizontalbarchart [slug]="'age_distribution'" style="height: 230px; margin-top: 40px; margin-bottom: -10px; width: 95%;"></horizontalbarchart>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col col-xl-10 offset-xl-1">
      <h2 class="text-center mt-3 mb-2">Neuregistrierungen</h2>
      <div class="row">
        <div class="col-md-12 mb-1 mb-md-0">
          <div class="card" style="display: flex; padding-bottom: 2rem; align-items: center;">
            <horizontalbarchart [slug]="'memberships_by_month'" style="margin-top: 40px; margin-bottom: -10px; width: 95%;"></horizontalbarchart>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-dashboard-rewards></app-dashboard-rewards>
</main>
