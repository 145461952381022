<div class="row">
  <div class="col-12">
    <h2 class="text-center mt-3 mb-2">Kundensegmente <span>nach Besuchshäufigkeit</span></h2>
    <div class="row">
      <div class="col-xl-10 offset-xl-1">
        <div class="row no-gutters">
          <div class="col-12 col-md mr-0 mr-md-1 mb-1">
            <div class="card">
              <div class="header">
                <span class="segment segment-new_customer">Neu</span>
                <br>
                1 Besuch
              </div>
              <div class="belly-tie">
                <span class="number">{{segments?.get("new_customer")?.customer_count_relative | percentage}}</span>
                <div style="width: 100%">
                  Kunden ({{segments?.get("new_customer")?.customer_count}})
                </div>
              </div>
              <div class="footer">
                <div class="multi-stat">
                  <div class="chart" *ngIf="segments?.get('new_customer')?.volume_absolute != 0">
                    <canvas baseChart
                            [data]="[segments?.get('new_customer')?.volume_absolute, segments?.get('walk_in_customer')?.volume_absolute || 100, segments?.get('loyal_customer')?.volume_absolute, segments?.get('vip')?.volume_absolute]"
                            [colors]="newCustomerColors"
                            [chartType]="'doughnut'"
                            [legend]="false"
                            [options]="{cutoutPercentage: 75, tooltips: {enabled: false}}"
                            style="width: 60px; height: 60px;"></canvas>
                  </div>
                  <div class="stats">
                    <div class="top">
                      <span class="number">{{segments?.get("new_customer")?.volume_absolute/1000 | number:'1.0-0'}}T€</span>
                      <span class="pipe"></span>
                      <span class="large-number segment-new_customer">{{segments?.get("new_customer")?.volume_relative | percentage}}</span>
                    </div>
                    <div class="bottom">
                      Umsatz
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 col-md mr-0 mr-md-1 mb-1">
            <div class="card">
              <div class="header">
                <span class="segment segment-walk_in_customer">Selten</span>
                <br>
                ∅ {{segments?.get("walk_in_customer")?.average_visits_per_month | number}} Besuche/Monat
              </div>
              <div class="belly-tie">
                <span class="number">{{segments?.get("walk_in_customer")?.customer_count_relative | percentage}}</span>
                <div style="width: 100%">
                  Kunden ({{segments?.get("walk_in_customer")?.customer_count}})
                </div>
              </div>
              <div class="footer">
                <div class="multi-stat">
                  <div class="chart" *ngIf="segments?.get('walk_in_customer')?.volume_absolute != 0">
                    <canvas baseChart
                            [data]="[segments?.get('walk_in_customer')?.volume_absolute, segments?.get('new_customer')?.volume_absolute || 100, segments?.get('loyal_customer')?.volume_absolute, segments?.get('vip')?.volume_absolute]"
                            [colors]="walkInCustomerColors"
                            [chartType]="'doughnut'"
                            [legend]="false"
                            [options]="{cutoutPercentage: 75, tooltips: {enabled: false}}"
                            style="width: 60px; height: 60px;"></canvas>
                  </div>
                  <div class="stats">
                    <div class="top">
                      <span class="number">{{segments?.get("walk_in_customer")?.volume_absolute/1000 | number:'1.0-0'}}T€</span>
                      <span class="pipe"></span>
                      <span class="large-number segment-walk_in_customer">{{segments?.get("walk_in_customer")?.volume_relative | percentage}}</span>
                    </div>
                    <div class="bottom">
                      Umsatz
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 col-md mr-0 mr-md-1 mb-1">
            <div class="card">
              <div class="header">
                <span class="segment segment-loyal_customer">Treu</span>
                <br>
                ∅ {{segments?.get("loyal_customer")?.average_visits_per_month | number}} Besuche/Monat
              </div>
              <div class="belly-tie">
                <span class="number">{{segments?.get("loyal_customer")?.customer_count_relative | percentage}}</span>
                <div style="width: 100%">
                  Kunden ({{segments?.get("loyal_customer")?.customer_count}})
                </div>
              </div>
              <div class="footer">
                <div class="multi-stat">
                  <div class="chart" *ngIf="segments?.get('loyal_customer')?.volume_absolute != 0">
                    <canvas baseChart
                            [data]="[segments?.get('loyal_customer')?.volume_absolute, segments?.get('new_customer')?.volume_absolute || 100, segments?.get('walk_in_customer')?.volume_absolute, segments?.get('vip')?.volume_absolute]"
                            [colors]="loyalCustomerColors"
                            [chartType]="'doughnut'"
                            [legend]="false"
                            [options]="{cutoutPercentage: 75, tooltips: {enabled: false}}"
                            style="width: 60px; height: 60px;"></canvas>
                  </div>
                  <div class="stats">
                    <div class="top">
                      <span class="number">{{segments?.get("loyal_customer")?.volume_absolute/1000 | number:'1.0-0'}}T€</span>
                      <span class="pipe"></span>
                      <span class="large-number segment-loyal_customer">{{segments?.get("loyal_customer")?.volume_relative | percentage}}</span>
                    </div>
                    <div class="bottom">
                      Umsatz
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 col-md mr-0 mb-1">
            <div class="card">
              <div class="header">
                <span class="segment segment-vip">VIP</span>
                <br>
                ∅ {{vipAverageVisits() | number}} Besuche/Monat
              </div>
              <div class="belly-tie">
                <span class="number">{{segments?.get("vip")?.customer_count_relative | percentage}}</span>
                <div style="width: 100%">
                  Kunden ({{segments?.get("vip")?.customer_count}})
                </div>
              </div>
              <div class="footer">
                <div class="multi-stat">
                  <div class="chart" *ngIf="segments?.get('vip')?.volume_absolute != 0">
                    <canvas baseChart
                            [data]="[segments?.get('vip')?.volume_absolute, segments?.get('new_customer')?.volume_absolute || 100, segments?.get('walk_in_customer')?.volume_absolute, segments?.get('loyal_customer')?.volume_absolute]"
                            [colors]="vipColors"
                            [chartType]="'doughnut'"
                            [legend]="false"
                            [options]="{cutoutPercentage: 75, tooltips: {enabled: false}}"
                            style="width: 60px; height: 60px;"></canvas>
                  </div>
                  <div class="stats">
                    <div class="top">
                      <span class="number">{{segments?.get("vip")?.volume_absolute/1000 | number:'1.0-0'}}T€</span>
                      <span class="pipe"></span>
                      <span class="large-number segment-vip">{{segments?.get("vip")?.volume_relative | percentage}}</span>
                    </div>
                    <div class="bottom">
                      Umsatz
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
