<canvas *ngIf="lineChartLabels.length > 0 && n > 20" [chartType]="'bar'" [colors]="lineChartColors"
        [datasets]="lineChartData"
        [labels]="lineChartLabels"
        [legend]="lineChartLegend"
        [options]="barChartOptions"
        baseChart
        class="chart"></canvas>
<div *ngIf="lineChartLabels.length > 0 && n <= 20"
     class="text-muted">Noch keine Daten verfügbar
</div>
<div *ngIf="!n"
     class="text-muted">Daten werden geladen
</div>
