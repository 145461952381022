import {Observable, throwError as observableThrowError} from 'rxjs';
import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {catchError, map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AutomationStatsService {

  URL: string = environment.api_base_url + 'stats/automations';

  constructor(private http: HttpClient) {}

  fetchAll(): Observable<any> {
    return this.http.get(`${this.URL}`)
        .pipe(
            map(this.extractData),
            catchError(this.handleError)
        );
  }

  // TODO does this work? & typing
  private extractData(res: any) {
    const automations = {};
    const data = res.data;
    data.forEach(el => {
      automations[el.automation] = el.data;
    });

    return automations;
  }

  private handleError(error: any) {
    // In a real world app, we might use a remote logging infrastructure
    // We'd also dig deeper into the error to get a better message
    const errMsg = (error.message) ? error.message :
      error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    console.error(errMsg); // log to console instead
    return observableThrowError(errMsg);
  }

}
