import {AfterViewInit, Component, Input, OnDestroy, ViewChild} from '@angular/core';
import {AddonTypes} from '../addon-types';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';
import {AddonSubscriptionService} from '../addon-subscription.service';
import {RecurringPromotionStore} from '../recurring-promotion.store';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-addon-promotion-recurring',
  templateUrl: './addon-promotion-recurring.component.html',
  styleUrls: ['./addon-promotion-recurring.component.scss']
})
export class AddonPromotionRecurringComponent implements AfterViewInit, OnDestroy {

  private ngUnsubscribe = new Subject();

  @Input()
  addonType: AddonTypes;

  @ViewChild('modalContent')
  modalContent;

  popupStore = null;

  constructor(private ngbModalService: NgbModal,
              private router: Router,
              private addonSubscriptionService: AddonSubscriptionService,
              private recurringPromotionStore: RecurringPromotionStore) {
  }

  ngAfterViewInit(): void {
    this.addonSubscriptionService.hasActiveSubscriptionFor(this.addonType).pipe(takeUntil(this.ngUnsubscribe)).subscribe(hasActiveSub => {
      if (!hasActiveSub && this.isOverdue()) {
        const result = this.ngbModalService.open(this.modalContent, {centered: true, size: 'xl'}).result;
        this.handleModalResult(result);
        this.recurringPromotionStore.updateTimestampFor(this.addonType);
      } else if (hasActiveSub) {
        this.recurringPromotionStore.updateTimestampFor(this.addonType);
      }
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onSubscribe() {
    if (this.addonType === AddonTypes.OBTAIN_REVIEW) {
      this.router.navigate(['automation', 'obtain_review']);
    } else if (this.addonType === AddonTypes.REFERRAL) {
      this.router.navigate(['/referral-settings']);
    }

  }

  isOverdue() {
    const timestamp = this.recurringPromotionStore.getTimestampFor(this.addonType);
    return !timestamp || timestamp < (new Date().getTime() - 3 * 7 * 24 * 60 * 60 * 1000);
  }

  private handleModalResult(result) {
    result.then(
      (res) => {
        if (res === 'more') {
          this.onSubscribe();
        }
      }
    );
  }
}
