<ng-container *ngIf="!subscribeModal && !unsubscribeModal && !popupModal">
  <div class="row row-promo">
    <div class="col-12 col-md-6 col-text">
      <h1>Kundengewinnung durch Bewertungen</h1>

      <p>Werde die Nr. 1 in den Suchergebnissen und bringe dein Google Unternehmensprofil auf das nächste Level.</p>

      <ul class="list-promo list-promo-checkmarks">
        <li>Konstant neue Bewertungen</li>
        <li>Kaum Zeitaufwand dank der Automatisierung</li>
        <li>Kunden können unkompliziert Bewertungen abgeben</li>
      </ul>

      <p>
        <button class="btn btn-primary btn-subscribe btn-lg mx-auto" (click)="onSubscribe.emit()">Hier aktivieren</button>
      </p>
    </div>

    <div class="col-12 col-md-6 col-img">
      <img class="img-fluid corners-rounded" src="assets/img/obtain-review/promotion/header.jpg">
    </div>
  </div>

  <div class="row row-promo background-colored corners-rounded">
    <div class="col-12 col-md-6 col-img">
      <img class="img-fluid corners-rounded" src="assets/img/obtain-review/promotion/price.jpg">
    </div>

    <div class="col-12 col-md-6 col-text">
      <ul class="list-promo list-promo-checkmarks">
        <li><strong>Fairer Preis</strong>&nbsp;&ndash; preiswert im Anbietervergleich</li>
        <li><strong>Keine Laufzeit</strong>&nbsp;&ndash; deaktiviere das Feature jederzeit</li>
        <li><strong>Ein Festpreis</strong>&nbsp;&ndash; es gibt keine versteckten Kosten</li>
      </ul>
    </div>
  </div>

  <div class="row row-promo">
    <div class="col-12 col-md-4 col-text col-keypoints">
      <div class="box-keypoint background-colored corners-rounded">
        <img src="assets/img/obtain-review/promotion/visitors-icon.svg" height="42" width="42">
        <h4>Neukunden gewinnen</h4>
        <p>
          Neukunden entscheiden sich eher für ein Geschäft mit einer hohen Anzahl an guten Google Bewertungen.
        </p>
      </div>
    </div>
    <div class="col-12 col-md-4 col-text col-keypoints">
      <div class="box-keypoint background-colored corners-rounded">
        <img src="assets/img/obtain-review/promotion/reach-icon.svg" height="42" width="42">
        <h4>Sichtbarkeit steigern</h4>
        <p>
          Viele positive Bewertungen steigern dein Local SEO und die Sichtbarkeit in den Google Suchergebnissen.
        </p>
      </div>
    </div>
    <div class="col-12 col-md-4 col-text col-keypoints">
      <div class="box-keypoint background-colored corners-rounded">
        <img src="assets/img/obtain-review/promotion/unique-icon.svg" height="42" width="42">
        <h4>Konkurrenz abhängen</h4>
        <p>
          Schaffe Vertrauen durch authentische Rezensionen und hänge so langfristig deine Konkurrenz ab.
        </p>
      </div>
    </div>
  </div>

  <div class="row row-promo">
    <div class="col-12 col-md-6 col-text">
      <h1>Per Google Integration zu mehr Bewertungen</h1>

      <p>Verknüpfe unkompliziert dein Google Unternehmenskonto und manage deine Google Bewertungen direkt im Mankido Merchant Center. </p>

      <ul class="list-promo list-promo-custom">
        <li>
          <img src="assets/img/obtain-review/promotion/plug-play-icon.svg">
          <strong>Plug&Play</strong>&nbsp;&ndash; einfach Google Konto hinterlegen und loslegen
        </li>
        <li>
          <img src="assets/img/obtain-review/promotion/autopilot-icon.svg">
          <strong>Autopilot</strong>&nbsp;&ndash; einmal eingerichtet läuft alles automatisiert
        </li>
      </ul>

      <p>
        <button class="btn btn-primary btn-subscribe btn-lg mx-auto" (click)="onSubscribe.emit()">Hier aktivieren</button>
      </p>
    </div>

    <div class="col-12 col-md-6 col-img">
      <img class="img-fluid corners-rounded" src="assets/img/obtain-review/promotion/google-my-business.jpg">
    </div>
  </div>

  <div class="row row-promo">
    <div class="col-12 text-center">
      <h1>Wieso sind Google Bewertungen ein Gamechanger?</h1>
    </div>
  </div>

  <div class="row row-promo">
    <div class="col-12 col-md-4 col-text col-keypoints">
      <div class="box-keypoint background-colored-lighter corners-rounded">
        <h2>8 von 10</h2>
        <p>
          Kunden lesen Bewertungen, bevor sie ein Geschäft besuchen.
        </p>
      </div>
    </div>
    <div class="col-12 col-md-4 col-text col-keypoints">
      <div class="box-keypoint background-colored corners-rounded">
        <h2>91%</h2>
        <p>
          der Käufer setzen Google Bewertungen mit einer Freundesempfehlung gleich.
        </p>
      </div>
    </div>
    <div class="col-12 col-md-4 col-text col-keypoints">
      <div class="box-keypoint background-colored-lighter corners-rounded">
        <h2>4.0 Sterne</h2>
        <p>
          werden von Kunden für eine Kaufentscheidung vorausgesetzt.
        </p>
      </div>
    </div>
  </div>

  <div class="row row-promo background-colored corners-rounded">
    <div class="col-12 col-md-6 col-text">
      <h1>Einfluss auf Platzierung in der Google Suche</h1>

      <ul class="list-promo list-promo-checkmarks">
        <li>Bewertungen sind für Google das 5. wichtigste Kriterium</li>
        <li>Regelmäßig neue Bewertungen sind besonders ausschlaggebend</li>
        <li>Viele Bewertungen erhöhen die Webseitenbesuche</li>
        <li>Hohe Webseitenbesuche erhöhen wiederum die Google Platzierung</li>
      </ul>
    </div>

    <div class="col-12 col-md-6 col-img">
      <img class="img-fluid corners-rounded" src="assets/img/obtain-review/promotion/placing.jpg">
    </div>
  </div>

  <div class="row row-promo">
    <div class="col-12 col-md-6 col-img">
      <img class="img-fluid corners-rounded" src="assets/img/obtain-review/promotion/with-mankido.jpg">
    </div>
    <div class="col-12 col-md-6 col-img">
      <img class="img-fluid corners-rounded" src="assets/img/obtain-review/promotion/without-mankido.jpg">
    </div>
  </div>

  <div class="row row-promo">
    <div class="col-12 d-flex justify-content-center">
      <button class="btn btn-primary btn-subscribe btn-lg mt-0" (click)="onSubscribe.emit()">Jetzt aktivieren</button>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="subscribeModal">
  <div class="row row-promo row-promo-modal">
    <div class="col-12 col-md-6 col-img">
      <img class="img-fluid corners-rounded" src="assets/img/obtain-review/promotion/modal-price.jpg">
    </div>

    <div class="col-12 col-md-6 col-text">

      <h1>Aktiviere jetzt das Addon!</h1>

      <ul class="list-promo list-promo-checkmarks">
        <li><strong>Keine Laufzeit</strong>&nbsp;&ndash; deaktiviere das Feature jederzeit</li>
        <li><strong>Ein Festpreis</strong>&nbsp;&ndash; es gibt keine versteckten Kosten</li>
        <li><strong>Dein Vorteil</strong>&nbsp;&ndash; bestes Preis-Leistungs-Verhältnis</li>
      </ul>

      <button class="btn btn-success btn-lg mx-auto" (click)="onSubscribe.emit()">Jetzt aktivieren</button>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="unsubscribeModal">
  <div class="row row-promo row-promo-modal">
    <div class="col-12 col-md-6 col-img">
      <img class="img-fluid corners-rounded" src="assets/img/obtain-review/promotion/with-mankido.jpg">
    </div>

    <div class="col-12 col-md-6 col-text">

      <h1>Willst du das Addon wirklich beenden?</h1>

      <ul class="list-promo list-promo-checkmarks">
        <li><strong>Fairer Preis</strong>&nbsp;&ndash; preiswert im Anbietervergleich</li>
        <li><strong>Keine Laufzeit</strong>&nbsp;&ndash; deaktiviere das Feature jederzeit</li>
        <li><strong>Ein Festpreis</strong>&nbsp;&ndash; es gibt keine versteckten Kosten</li>
      </ul>

      <button class="btn btn-danger btn-lg mx-auto" (click)="onSubscribe.emit()">Jetzt beenden</button>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="popupModal">
  <div class="row row-promo row-promo-modal">
    <div class="col-12 col-md-6 col-text">
      <div class="badge badge-pill badge-promo">Neu</div>
      <h1>Mehr Google Bewertungen mit Mankido</h1>

      <ul class="list-promo list-promo-checkmarks">
        <li>Mehr Neukunden überzeugen</li>
        <li>Kontinuierlich neue Bewertungen erhalten</li>
        <li>Kaum Zeitaufwand dank Automatisierung</li>
        <li>Stärker von der Konkurrenz abheben</li>
        <li>Bessere Sichtbarkeit auf Google</li>
      </ul>

      <button class="btn btn-primary btn-subscribe btn-lg w-fit-content" style="width: fit-content;" (click)="onSubscribe.emit()">Mehr erfahren</button>
    </div>

    <div class="col-12 col-md-6 col-img">
      <img class="img-fluid corners-rounded" src="assets/img/obtain-review/promotion/popup.jpg">
    </div>
  </div>
</ng-container>
