import {Observable, throwError as observableThrowError} from 'rxjs';
import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {BestcustomerStats} from './bestcustomer.stats';
import {catchError, map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BestcustomersStatsService {

  URL: string = environment.api_base_url + 'stats/best_customers';

  constructor(private http: HttpClient) {
  }

  fetchAll(): Observable<BestcustomerStats[]> {
    return this.http.get(`${this.URL}`)
        .pipe(
            map((res: any) => {
              res.data = res.data.map(stat => new BestcustomerStats(stat));
              return res.data;
            }),
            catchError(this.handleError)
        );
  }

  private handleError(error: any) {
    // In a real world app, we might use a remote logging infrastructure
    // We'd also dig deeper into the error to get a better message
    const errMsg = (error.message) ? error.message :
        error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    console.error(errMsg); // log to console instead
    return observableThrowError(errMsg);
  }

}
