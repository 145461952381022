import {Injectable} from '@angular/core';
import {AddonTypes} from './addon-types';

@Injectable({
  providedIn: 'root'
})
export class RecurringPromotionStore {
  private static STORAGE_KEY = 'promotion-timestamps';

  constructor() {
  }

  updateTimestampFor(addonType: AddonTypes | any) {
    const store = this.loadStore();
    store[addonType] = new Date().getTime();
    this.saveStore(store);
  }

  getTimestampFor(addonType: AddonTypes | any) {
    return this.loadStore()[addonType];
  }

  private loadStore(): {} {
    return JSON.parse(localStorage.getItem(RecurringPromotionStore.STORAGE_KEY)) || {};
  }

  private saveStore(store: {}) {
    const serialized = JSON.stringify(store);
    localStorage.setItem(RecurringPromotionStore.STORAGE_KEY, serialized);
  }
}
