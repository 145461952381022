<ng-container *ngIf="percentages && n > 20">
  <div class="row pt-2">
    <div class="col-6">
      <div class="silhouette">
        <img alt="" src="assets/img/gender_female.svg">
        <img [ngStyle]="{
          'clip-path': 'inset(' + (1-percentages.female)*100 + '% 0 0 0)',
          '-webkit-clip-path': 'inset(' + (1-percentages.female)*100 + '% 0 0 0)'
        }" alt="" src="assets/img/gender_female.svg">
      </div>
    </div>
    <div class="col-6">
      <div class="silhouette">
        <img alt="" src="assets/img/gender_male.svg">
        <img [ngStyle]="{
          'clip-path': 'inset(' +(1-percentages.male)*100 + '% 0 0 0)',
          '-webkit-clip-path': 'inset(' + (1-percentages.male)*100 + '% 0 0 0)'
        }" alt="" src="assets/img/gender_male.svg" style="position: relative">
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <p><span class="large">{{(percentages.female || 0) | percentage}}</span><br>weiblich</p>
    </div>
    <div class="col-6">
      <p><span class="large">{{(percentages.male || 0) | percentage }}</span><br>männlich</p>
    </div>
  </div>
</ng-container>
<div *ngIf="percentages && n <= 20"
     class="text-muted">Noch keine Daten verfügbar
</div>
<div *ngIf="!n"
     class="text-muted">Daten werden geladen
</div>
