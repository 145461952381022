import {Component, OnInit} from '@angular/core';
import {faPlane} from '@fortawesome/pro-light-svg-icons/faPlane';
import {faShoppingCart} from '@fortawesome/pro-light-svg-icons/faShoppingCart';
import {faEuroSign} from '@fortawesome/pro-light-svg-icons/faEuroSign';
import {faSort} from '@fortawesome/free-solid-svg-icons/faSort';
import {AutomationStatsService} from './automation.stats.service';
import {BetaService} from '../../beta/beta.service';

@Component({
  selector: 'app-dashboard-automations',
  templateUrl: './automations.component.html',
  styleUrls: ['./automations.component.scss']
})
export class DashboardAutomationsComponent implements OnInit {
  faPlane = faPlane;
  faShoppingCart = faShoppingCart;
  faEuroSign = faEuroSign;
  faSort = faSort;
  automationStats: any;
  sums: {
    deliveries: number,
    visits: number,
    revenue: number
  };

  constructor(private automationStatsService: AutomationStatsService) {
  }

  ngOnInit() {
    this.automationStatsService.fetchAll().subscribe((automationStats) => {
      this.automationStats = automationStats;
      this.recalculateSums();
    });
  }

  recalculateSums() {
    const sums = {
      deliveries: 0,
      visits: 0,
      revenue: 0
    };

    Object.keys(this.automationStats).forEach(a => {
      sums.deliveries += this.automationStats[a].deliveries;
      if (this.automationStats[a].visits) {
        sums.visits += this.automationStats[a].visits;
      }
      if (this.automationStats[a].revenue) {
        sums.revenue += this.automationStats[a].revenue;
      }
    });

    this.sums = sums;
  }
}
