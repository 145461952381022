export class BestcustomerStats {
  email: string;
  created_at: Date;
  visits: number;
  revenue: number;

  constructor(v: Partial<BestcustomerStats> = {}) {
    Object.assign(this, v);
  }
}
