import {Component, OnInit} from '@angular/core';
import {SegmentData} from '../../segmentation/segmentdata';
import {PartnerService} from '../../partner/partner.service';
import {Partner} from '../../partner/partner';
import {SegmentationService} from '../../segmentation/segmentation.service';
import {MerchantService} from '../../merchant/merchant.service';

@Component({
  selector: 'app-segmentation',
  templateUrl: './segmentation.component.html',
  styleUrls: ['./segmentation.component.scss']

})
export class SegmentationComponent implements OnInit {
  segments: Map<string, SegmentData> = new Map();
  partner: Partner = new Partner({});
  context: any = {};

  new_customer = '#82d581';
  walk_in_customer = '#31aedd';
  loyal_customer = '#569dd0';
  vip = '#926ec3';
  grey = '#eee';

  newCustomerColors  = [{backgroundColor: [this.new_customer, this.grey, this.grey, this.grey], borderWidth: [0, 0, 0, 0]}];
  walkInCustomerColors  = [{backgroundColor: [this.walk_in_customer, this.grey, this.grey, this.grey], borderWidth: [0, 0, 0, 0]}];
  loyalCustomerColors  = [{backgroundColor: [this.loyal_customer, this.grey, this.grey, this.grey], borderWidth: [0, 0, 0, 0]}];
  vipColors  = [{backgroundColor: [this.vip, this.grey, this.grey, this.grey], borderWidth: [0, 0, 0, 0]}];

  constructor(private partnerService: PartnerService,
              private segmentationService: SegmentationService,
              private merchantService: MerchantService) {
  }

  ngOnInit() {
    this.merchantService.currentContext.subscribe(context => {
      this.context = context;

      setTimeout(() => {
        this.segmentationService.fetchAll().subscribe((segments) => {
          this.segments = segments;
        });
      }, 100);
    });
  }

  vipAverageVisits() {
    if (this.segments && this.segments.get('vip') && this.segments.get('vip').average_visits_per_month) {
      if (this.segments.get('vip').average_visits_per_month < this.segments.get('loyal_customer').average_visits_per_month + 1) {
        return this.segments.get('vip').average_visits_per_month + 2;
      } else {
        return this.segments.get('vip').average_visits_per_month;
      }
    } else {
      return 0;
    }
  }
}
