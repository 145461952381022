<div class="row">
  <div class="col">
    <h2 class="text-center mt-3 mb-2">Marketing Automatisierungen</h2>
  </div>
</div>

<div class="row">
  <div class="col col-xl-10 offset-xl-1">
    <div class="row mb-1">
      <div class="col-md pb-1 pb-md-0" style="padding-right: 9px;">
        <div class="card colored-card" style="background-color: #31AEDD">
          <div class="row no-gutters m-1">
            <div class="col col-5">
              <fa-icon [icon]="faPlane"></fa-icon>
            </div>
            <div class="col col-7">
              <h2>
                {{(sums?.deliveries | number)}}
                <ngx-skeleton-loader *ngIf="!sums"></ngx-skeleton-loader>
              </h2>
              <p>Deals versendet</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md pb-1 pb-md-0" style="padding-right: 12px; padding-left: 12px;">
        <div class="card colored-card" style="background-color: #5C91D9">
          <div class="row no-gutters m-1">
            <div class="col col-5">
              <fa-icon [icon]="faShoppingCart"></fa-icon>
            </div>
            <div class="col col-7">
              <h2>
                {{(sums?.visits | number) || " "}}
                <ngx-skeleton-loader *ngIf="!sums"></ngx-skeleton-loader>
              </h2>
              <p>Besuche vor Ort</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md pb-1 pb-md-0" style="padding-left: 9px;">
        <div class="card colored-card" style="background-color: #926EC3">
          <div class="row no-gutters m-1">
            <div class="col col-5">
              <fa-icon [icon]="faEuroSign"></fa-icon>
            </div>
            <div class="col col-7">
              <h2>
                {{(sums?.revenue | number) || " "}}
                <ngx-skeleton-loader *ngIf="!sums"></ngx-skeleton-loader>
              </h2>
              <p>Mehrumsatz</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card" style="overflow-x: auto; overflow-y: hidden">
      <table class="table card-table">
        <thead>
        <tr>
          <th colspan="4">
          <span class="time-range-picker" *ngIf="false">
            30 Tage
            <fa-icon [icon]="faSort" style="color: #d8d8d8; padding-left: 5px"></fa-icon>
          </span>
            Performance im Detail
          </th>
        </tr>
        <tr>
          <th>
            Deal Art
          </th>
          <th style="width: 20%">
            Verschickt
          </th>
          <th style="width: 10%">
            Besuche
          </th>
          <th style="width: 20%">
            Umsatz
          </th>
        </tr>
        </thead>
        <tbody>
        <ng-container *ngIf="automationStats">
          <tr>
            <td class="d-flex align-items-center border-top-0">
              <img src="assets/img/rewardloyalty.svg" class="line-icon" alt="">
              Willkommensnachricht
            </td>
            <ng-container
              *ngIf="automationStats.first_visit && automationStats.first_visit.visits >= 5 && automationStats.first_visit.revenue >= 100">
              <td>
                {{automationStats.first_visit.deliveries}}
              </td>
              <td>
                {{automationStats.first_visit.visits}}
              </td>
              <td>
                {{automationStats.first_visit.revenue | currency:'EUR'}}
              </td>
            </ng-container>
            <ng-container *ngIf="!automationStats.first_visit">
              <td colspan="3">
                <a [routerLink]="['/automation/first_visit']">Jetzt aktivieren</a>
              </td>
            </ng-container>
            <ng-container *ngIf="automationStats.first_visit
            && (automationStats.first_visit.visits < 5 || automationStats.first_visit.revenue < 100)">
              <td class="text-muted" colspan="3">
                Zu wenig Daten verfügbar
              </td>
            </ng-container>
          </tr>

          <tr>
            <td class="d-flex align-items-center">
              <img src="assets/img/boostloyalty.svg" class="line-icon" alt="">
              Kundenbindung stärken
            </td>
            <ng-container
              *ngIf="automationStats.growth && automationStats.growth.visits >= 5 && automationStats.growth.revenue >= 100">
              <td>
                {{automationStats.growth.deliveries}}
              </td>
              <td>
                {{automationStats.growth.visits}}
              </td>
              <td>
                {{automationStats.growth.revenue | currency:'EUR'}}
              </td>
            </ng-container>
            <ng-container *ngIf="!automationStats.growth">
              <td colspan="3">
                <a [routerLink]="['/automation/growth']">Jetzt aktivieren</a>
              </td>
            </ng-container>
            <ng-container *ngIf="automationStats.growth
            && (automationStats.growth.visits < 5 || automationStats.growth.revenue < 100)">
              <td class="text-muted" colspan="3">
                Zu wenig Daten verfügbar
              </td>
            </ng-container>
          </tr>

          <tr>
            <td class="d-flex align-items-center">
              <img src="assets/img/birthdaydeal.svg" class="line-icon" alt="">
              Geburtstags-Deal
            </td>
            <ng-container
              *ngIf="automationStats.birthday && automationStats.birthday.visits >= 5 && automationStats.birthday.revenue >= 100">
              <td>
                {{automationStats.birthday.deliveries}}
              </td>
              <td>
                {{automationStats.birthday.visits}}
              </td>
              <td>
                {{automationStats.birthday.revenue | currency:'EUR'}}
              </td>
            </ng-container>
            <ng-container *ngIf="!automationStats.birthday">
              <td colspan="3">
                <a [routerLink]="['/automation/birthday']">Jetzt aktivieren</a>
              </td>
            </ng-container>
            <ng-container *ngIf="automationStats.birthday
            && (automationStats.birthday.visits < 5 || automationStats.birthday.revenue < 100)">
              <td class="text-muted" colspan="3">
                Zu wenig Daten verfügbar
              </td>
            </ng-container>
          </tr>

          <tr>
            <td class="d-flex align-items-center">
              <img src="assets/img/churnprevention.svg" class="line-icon" alt="">
              Kunden zurückgewinnen
            </td>
            <ng-container *ngIf="(automationStats.at_risk || automationStats.lapsed || automationStats.lost)
            && automationStats.at_risk?.visits + automationStats.lapsed?.visits + automationStats.lost?.visits >= 5
            && automationStats.at_risk?.revenue + automationStats.lapsed?.revenue + automationStats.lost?.revenue >= 100">
              <td>
                {{automationStats.at_risk?.deliveries + automationStats.lapsed?.deliveries + automationStats.lost?.deliveries}}
              </td>
              <td>
                {{automationStats.at_risk?.visits + automationStats.lapsed?.visits + automationStats.lost?.visits}}
              </td>
              <td>
                {{automationStats.at_risk?.revenue + automationStats.lapsed?.revenue + automationStats.lost?.revenue | currency:'EUR'}}
              </td>
            </ng-container>
            <ng-container *ngIf="!automationStats.at_risk && !automationStats.lapsed && !automationStats.lost">
              <td colspan="3">
                <a [routerLink]="['/automation/churn-prevention']">Jetzt aktivieren</a>
              </td>
            </ng-container>
            <ng-container *ngIf="(automationStats.at_risk || automationStats.lapsed || automationStats.lost)
            && (automationStats.at_risk?.visits + automationStats.lapsed?.visits + automationStats.lost?.visits < 5
            || automationStats.at_risk?.revenue + automationStats.lapsed?.revenue + automationStats.lost?.revenue < 100)">
              <td class="text-muted" colspan="3">
                Zu wenig Daten verfügbar
              </td>
            </ng-container>
          </tr>

        </ng-container>
        <tr *ngIf="!automationStats">
          <td colspan="4" class="text-center" style="height: 291px; line-height: 290px; padding: 0;">
            Daten werden geladen...
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="card mt-2" style="overflow-x: auto; overflow-y: hidden">
      <table class="table card-table">
        <thead>
        <tr>
          <th>
            Bewertungen
          </th>
          <th style="width: 10%">
            Verschickt
          </th>
          <th style="width: 10%">
            Geöffnet
          </th>
          <th style="width: 10%">
            Geklickt
          </th>
          <th style="width: 20%">
            Neue Bewertungen
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngIf="automationStats">
          <td>
            <img src="assets/img/obtain-review.svg" class="line-icon" alt="">
            Google Bewertungen
          </td>
          <ng-container *ngIf="automationStats.obtain_review
            && (automationStats?.obtain_review?.total_open_count >= 5 || automationStats?.obtain_review?.total_tracked_redirects >= 0)">
            <td>{{automationStats?.obtain_review?.deliveries}}</td>
            <td>{{automationStats?.obtain_review?.total_open_count}}</td>
            <td>{{automationStats?.obtain_review?.total_tracked_redirects}}</td>
            <td>{{automationStats?.obtain_review?.total_tracked_reviews}}</td>
          </ng-container>
          <ng-container *ngIf="!automationStats.obtain_review">
            <td colspan="3">
              <a [routerLink]="['/automation/obtain_review']">Jetzt aktivieren</a>
            </td>
          </ng-container>
          <ng-container *ngIf="automationStats.obtain_review
            && (automationStats?.obtain_review?.total_open_count < 5 && automationStats?.obtain_review?.total_tracked_redirects < 0)">
            <td class="text-muted" colspan="3">
              Zu wenig Daten verfügbar
            </td>
          </ng-container>
        </tr>
        <tr *ngIf="!automationStats">
          <td colspan="4" class="text-center">
            Daten werden geladen...
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="card mt-2" style="overflow-x: auto; overflow-y: hidden">
      <table class="table card-table">
        <thead>
        <tr>
          <th>
            Weiterempfehlungen
          </th>
          <th style="width: 10%">
            Eingeladen
          </th>
          <th style="width: 10%">
            Registriert
          </th>
          <th style="width: 20%">
            Neue Kunden
          </th>
          <th style="width: 20%">
            Umsatz
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngIf="automationStats">
          <td>
            <img src="assets/img/referral.svg" class="line-icon" alt="">
            Kunden werben Kunden
          </td>
          <ng-container *ngIf="automationStats.referral && automationStats?.referral?.invited >= 0">
            <td>{{automationStats?.referral?.invited}}</td>
            <td>{{automationStats?.referral?.accepted}}</td>
            <td>{{automationStats?.referral?.rewarded}}</td>
            <td>{{automationStats?.referral?.revenue / 100  | currency:'EUR'}}</td>
          </ng-container>
          <ng-container *ngIf="!automationStats.referral">
            <td colspan="3">
              <a [routerLink]="['/automation/obtain_review']">Jetzt aktivieren</a>
            </td>
          </ng-container>
          <ng-container *ngIf="automationStats.referral && automationStats?.referral?.invited < 0">
            <td class="text-muted" colspan="3">
              Zu wenig Daten verfügbar
            </td>
          </ng-container>
        </tr>
        <tr *ngIf="!automationStats">
          <td colspan="4" class="text-center">
            Daten werden geladen...
          </td>
        </tr>
        </tbody>
      </table>
    </div>

  </div>
</div>

