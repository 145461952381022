export class ChartData {
  static DATE_PATTERN = /^(\d\d\d\d)(\-)?(\d\d)(\-)?(\d\d)$/i;
  static ISO8601_PATTERN = /(\d\d\d\d)(\-)?(\d\d)(\-)?(\d\d)(T)?(\d\d)(:)?(\d\d)?(:)?(\d\d)?([\.,]\d+)?($|Z|([\+\-])(\d\d)(:)?(\d\d)?)/i;
  static DECIMAL_SEPARATOR = String(1.5).charAt(1);

  rawData: any;
  data: any;
  labels: any;
  options: any = {
    scales: {
      yAxes: [
        {
          gridLines: {
            color: '#eee',
            zeroLineColor: '#eee'
          },
          ticks: {
            maxTicksLimit: 10,
            fontFamily: 'Open Sans'
          },
          scaleLabel: {
            fontSize: 16,
            // fontStyle: "bold",
            fontColor: '#ddd'
          },
          stacked: false
        }
      ],
      xAxes: [{
        stacked: false,
        beginAtZero: true,
        gridLines: {
          drawOnChartArea: false,
          color: '#eee',
          zeroLineColor: 'white'
        },
        time: {},
        ticks: {
          maxRotation: 0,
          fontFamily: 'Open Sans',
          callback: function (value, index, values) {
            if (index % 7 != 4 || index == 0) {
              return '';
            } else {
              return value;
            }
          }
        }
      }]
    },
    legend: {},
    maintainAspectRatio: false
  };

  constructor(rawData, options?) {
    this.rawData = this.processSeries(rawData, options, 'datetime');
    this.createDataTable(this.rawData, 'line');
  }

  private static detectDiscrete(series) {
    let i, j, data;
    for (i = 0; i < series.length; i++) {
      data = ChartData.toArr(series[i].data);
      for (j = 0; j < data.length; j++) {
        if (!ChartData.isDate(data[j][0])) {
          return true;
        }
      }
    }
    return false;
  }

  private static toArr(n) {
    if (!ChartData.isArray(n)) {
      let arr = [], i;
      for (i in n) {
        if (n.hasOwnProperty(i)) {
          arr.push([i, n[i]]);
        }
      }
      n = arr;
    }
    return n;
  }

  private static isMinute(d) {
    return d.getMilliseconds() === 0 && d.getSeconds() === 0;
  }

  private static isHour(d) {
    return ChartData.isMinute(d) && d.getMinutes() === 0;
  }

  private static isDay(d) {
    return ChartData.isHour(d) && d.getHours() === 0;
  }

  private static isWeek(d, dayOfWeek) {
    return ChartData.isDay(d) && d.getDay() === dayOfWeek;
  }

  private static isMonth(d) {
    return ChartData.isDay(d) && d.getDate() === 1;
  }

  private static isYear(d) {
    return ChartData.isMonth(d) && d.getMonth() === 0;
  }

  private static isDate(obj) {
    return !Number.isNaN(ChartData.toDate(obj)) && ChartData.toStr(obj).length >= 6;
  }

  private static toDate(n) {
    let matches, year, month, day;
    if (typeof n !== 'object') {
      if (typeof n === 'number') {
        n = new Date(n * 1000); // ms
      } else if ((matches = n.match(ChartData.DATE_PATTERN))) {
        year = parseInt(matches[1], 10);
        month = parseInt(matches[3], 10) - 1;
        day = parseInt(matches[5], 10);
        return new Date(year, month, day);
      } else { // str
        // try our best to get the str into iso8601
        // TODO be smarter about this
        const str = n.replace(/ /, 'T').replace(' ', '').replace('UTC', 'Z');
        n = ChartData.parseISO8601(str) || new Date(n);
      }
    }
    return n;
  }

  private static toStr(n) {
    return '' + n;
  }

  private static toFloat(n) {
    return parseFloat(n);
  }

  private static parseISO8601(input) {
    let day, hour, matches, milliseconds, minutes, month, offset, result, seconds, type, year;
    type = Object.prototype.toString.call(input);
    if (type === '[object Date]') {
      return input;
    }
    if (type !== '[object String]') {
      return;
    }
    matches = input.match(ChartData.ISO8601_PATTERN);
    if (matches) {
      year = parseInt(matches[1], 10);
      month = parseInt(matches[3], 10) - 1;
      day = parseInt(matches[5], 10);
      hour = parseInt(matches[7], 10);
      minutes = matches[9] ? parseInt(matches[9], 10) : 0;
      seconds = matches[11] ? parseInt(matches[11], 10) : 0;
      milliseconds = matches[12] ? parseFloat(ChartData.DECIMAL_SEPARATOR + matches[12].slice(1)) * 1000 : 0;
      result = Date.UTC(year, month, day, hour, minutes, seconds, milliseconds);
      if (matches[13] && matches[14]) {
        offset = matches[15] * 60;
        if (matches[17]) {
          offset += parseInt(matches[17], 10);
        }
        offset *= matches[14] === '-' ? -1 : 1;
        result -= offset * 60 * 1000;
      }
      return new Date(result);
    }
  }

  private static toFormattedKey(key, keyType) {
    if (keyType === 'number') {
      key = ChartData.toFloat(key);
    } else if (keyType === 'datetime') {
      key = ChartData.toDate(key);
    } else {
      key = ChartData.toStr(key);
    }
    return key;
  }

  private static formatSeriesData(data, keyType) {
    let r = [], key, j;
    for (j = 0; j < data.length; j++) {
      key = ChartData.toFormattedKey(data[j][0], keyType);
      r.push([key, ChartData.toFloat(data[j][1])]);
    }
    if (keyType === 'datetime') {
      r.sort(ChartData.sortByTime);
    }
    return r;
  }

  private static sortByTime(a, b) {
    return a[0].getTime() - b[0].getTime();
  }

  private static sortByNumber(a, b) {
    return a - b;
  }

  private static isArray(letiable) {
    return Object.prototype.toString.call(letiable) === '[object Array]';
  }

  private static isFunction(letiable) {
    return letiable instanceof Function;
  }

  private static isPlainObject(letiable) {
    return !ChartData.isFunction(letiable) && letiable instanceof Object;
  }

  // https://github.com/madrobby/zepto/blob/master/src/zepto.js
  private static extend(target, source) {
    let key;
    for (key in source) {
      if (ChartData.isPlainObject(source[key]) || ChartData.isArray(source[key])) {
        if (ChartData.isPlainObject(source[key]) && !ChartData.isPlainObject(target[key])) {
          target[key] = {};
        }
        if (ChartData.isArray(source[key]) && !ChartData.isArray(target[key])) {
          target[key] = [];
        }
        ChartData.extend(target[key], source[key]);
      } else if (source[key] !== undefined) {
        target[key] = source[key];
      }
    }
  }

  private static merge(obj1, obj2) {
    const target = {};
    ChartData.extend(target, obj1);
    ChartData.extend(target, obj2);
    return target;
  }

  private static allZeros(data) {
    let i, j, d;
    for (i = 0; i < data.length; i++) {
      d = data[i].data;
      for (j = 0; j < d.length; j++) {
        if (d[j][1] != 0) {
          return false;
        }
      }
    }
    return true;
  }

  private static addOpacity(hex, opacity) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? 'rgba(' + parseInt(result[1], 16) + ', ' + parseInt(result[2], 16) + ', ' + parseInt(result[3], 16) + ', ' + opacity + ')' : hex;
  }

  public getData() {
    return this.data;
  }

  public getOptions() {
    return this.options;
  }

  public getLabels() {
    return this.labels;
  }

  processSeries(series, opts: any = {}, keyType) {
    let i;

    // see if one series or multiple
    if (!ChartData.isArray(series) || typeof series[0] !== 'object' || ChartData.isArray(series[0])) {
      series = [{name: opts.label || 'Wert', data: series}];
      this.options.legend = true;
    } else {
      this.options.legend = false;
    }

    this.options.discrete = ChartData.detectDiscrete(series);

    if (this.options.discrete) {
      keyType = 'string';
    }

    // right format
    for (i = 0; i < series.length; i++) {
      series[i].data = ChartData.formatSeriesData(ChartData.toArr(series[i].data), keyType);
    }

    return series;
  }

  private createDataTable(data, chartType) {
    const datasets = [];
    const labels = [];

    const defaultColors = [
      '#3366CC', '#DC3912', '#FF9900', '#109618', '#990099', '#3B3EAC', '#0099C6',
      '#DD4477', '#66AA00', '#B82E2E', '#316395', '#994499', '#22AA99', '#AAAA11',
      '#6633CC', '#E67300', '#8B0707', '#329262', '#5574A6', '#3B3EAC'
    ];

    const colors = this.options.colors || defaultColors;

    let day = true;
    let week = true;
    let dayOfWeek;
    let month = true;
    let year = true;
    let hour = true;
    let minute = true;
    const detectType = (chartType === 'line' || chartType === 'area') && !this.options.discrete;

    const series = data;

    const sortedLabels = [];

    let i, j, s, d, key, rows = [];
    for (let i = 0; i < series.length; i++) {
      s = series[i];

      for (j = 0; j < s.data.length; j++) {
        d = s.data[j];
        key = detectType ? d[0].getTime() : d[0];
        if (!rows[key]) {
          rows[key] = new Array(series.length);
        }
        rows[key][i] = ChartData.toFloat(d[1]);
        if (sortedLabels.indexOf(key) === -1) {
          sortedLabels.push(key);
        }
      }
    }

    if (detectType) {
      sortedLabels.sort(ChartData.sortByNumber);
    }

    const rows2 = [];
    for (j = 0; j < series.length; j++) {
      rows2.push([]);
    }

    let value;
    let k;
    for (k = 0; k < sortedLabels.length; k++) {
      i = sortedLabels[k];
      if (detectType) {
        value = new Date(ChartData.toFloat(i));
        // TODO make this efficient
        day = day && ChartData.isDay(value);
        if (!dayOfWeek) {
          dayOfWeek = value.getDay();
        }
        week = week && ChartData.isWeek(value, dayOfWeek);
        month = month && ChartData.isMonth(value);
        year = year && ChartData.isYear(value);
        hour = hour && ChartData.isHour(value);
        minute = minute && ChartData.isMinute(value);
      } else {
        value = i;
      }
      labels.push(value);
      for (j = 0; j < series.length; j++) {
        rows2[j].push(rows[i][j]);
      }
    }


    for (i = 0; i < series.length; i++) {
      s = series[i];

      const backgroundColor = chartType !== 'line' ? ChartData.addOpacity(colors[i], 0.5) : colors[i];

      const dataset = {
        label: s.name,
        data: rows2[i],
        fill: true,
        borderColor: colors[i],
        backgroundColor: backgroundColor,
        pointBackgroundColor: colors[i],
        borderWidth: 1
      };

      datasets.push(ChartData.merge(dataset, s.library || {}));
    }

    if (detectType && labels.length > 0) {
      let minTime = labels[0].getTime();
      let maxTime = labels[0].getTime();
      for (i = 1; i < labels.length; i++) {
        value = labels[i].getTime();
        if (value < minTime) {
          minTime = value;
        }
        if (value > maxTime) {
          maxTime = value;
        }
      }

      const timeDiff = (maxTime - minTime) / (86400 * 1000.0);

      if (!this.options.scales.xAxes[0].time.unit) {
        let step;
        if (year || timeDiff > 365 * 10) {
          this.options.scales.xAxes[0].time.unit = 'year';
          step = 365;
        } else if (month || timeDiff > 30 * 10) {
          this.options.scales.xAxes[0].time.displayFormats = {day: 'DD.MM.'};
          this.options.scales.xAxes[0].time.unit = 'month';
          step = 30;
        } else if (day || timeDiff > 10) {
          this.options.scales.xAxes[0].time.displayFormats = {day: 'DD.MM.'};
          this.options.scales.xAxes[0].time.unit = 'day';
          step = 1;
        } else if (hour || timeDiff > 0.5) {
          this.options.scales.xAxes[0].time.displayFormats = {hour: 'HH:mm'};
          this.options.scales.xAxes[0].time.unit = 'hour';
          step = 1 / 24.0;
        } else if (minute) {
          this.options.scales.xAxes[0].time.displayFormats = {minute: 'HH:mm'};
          this.options.scales.xAxes[0].time.unit = 'minute';
          step = 1 / 24.0 / 60.0;
        }

        if (step && timeDiff > 0) {
          // ACHTUNG
          // hier wird eigentlich die breite des elements gebraucht
          let unitStepSize = 1;
          if (week && step === 1) {
            unitStepSize = Math.ceil(unitStepSize / 7.0) * 7;
          }
          this.options.scales.xAxes[0].time.unitStepSize = unitStepSize;
        }
      }

      if (!this.options.scales.xAxes[0].time.tooltipFormat) {
        if (day) {
          this.options.scales.xAxes[0].time.tooltipFormat = 'DD.MM.';
        } else if (hour) {
          this.options.scales.xAxes[0].time.tooltipFormat = 'HH:mm';
        } else if (minute) {
          this.options.scales.xAxes[0].time.tooltipFormat = 'HH:mm';
        }
      }

    }

    this.options.scales.xAxes[0].type = this.options.discrete ? 'category' : 'time';

    this.data = datasets;
    this.labels = labels;
  }
}
