import {Component, Input, OnInit} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {MerchantService} from '../../../merchant/merchant.service';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'genderchart',
  templateUrl: './genderchart.component.html',
  styleUrls: ['./genderchart.component.scss']
})
export class GenderchartComponent implements OnInit {
  @Input() slug: string;
  @Input() delay = 0;
  context: any = {};
  percentages = {
    male: 0,
    female: 0
  };
  n: number;

  constructor(private authHttp: HttpClient,
              private merchantService: MerchantService) {
  }

  ngOnInit() {
    this.merchantService.currentContext.subscribe(context => {
      this.context = context;
      this.loadData();
    });
  }

  loadData() {
    setTimeout(() => {
      this.reload();
    }, this.delay);
  }

  private reload() {
    this.authHttp.get(environment.api_base_url + 'stats/' + this.slug).subscribe((res: any) => {
      // TODO: FIX THIS!
      this.percentages = res.data;
      this.n = res.n;
    });
  }
}
