import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './auth';
import {Logout} from './auth/logout';
import {Missing} from './missing/missing.component';
import {UnauthorizedComponent} from './unauthorized/unauthorized.component';
import {NgModule} from '@angular/core';
import {DashboardComponent} from './dashboard/dashboard.component';

const routes: Routes = [
  {path: '', redirectTo: '/dashboard', pathMatch: 'full'},

  {path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard]},

  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
  },

  {path: 'logout', component: Logout},

  {
    path: 'loyalty',
    loadChildren: () => import('./loyalty/loyalty.module').then(m => m.LoyaltyModule),
    canActivate: [AuthGuard]
  },

  {
    path: 'partner',
    loadChildren: () => import('./partner/partner.module').then(m => m.PartnerModule),
    canActivate: [AuthGuard]
  },

  {
    path: 'campaigns',
    loadChildren: () => import('./campaigns/campaigns.module').then(m => m.CampaignsModule),
    canActivate: [AuthGuard]
  },

  {
    path: 'pos_experiences',
    loadChildren: () => import('./pos-experiences/pos-experiences.module').then(m => m.PosExperiencesModule),
    canActivate: [AuthGuard]
  },

  {
    path: 'special_points_events/:id',
    loadChildren: () => import('./special-points-events/special-points-event-edit/special-points-event-edit.module')
      .then(m => m.SpecialPointsEventEditModule),
    canActivate: [AuthGuard]
  },

  {
    path: 'automation',
    loadChildren: () => import('./automation/automation.module').then(m => m.AutomationModule),
    canActivate: [AuthGuard]
  },

  {
    path: 'invoices',
    loadChildren: () => import('./invoices/invoices.module').then(m => m.InvoicesModule),
    canActivate: [AuthGuard]
  },

  {
    path: 'contracts',
    loadChildren: () => import('./contracts/contracts.module').then(m => m.ContractsModule),
    canActivate: [AuthGuard]
  },

  {
    path: 'dpa-downloads',
    loadChildren: () => import('./dpa/dpa.module').then(m => m.DpaModule),
    canActivate: [AuthGuard]
  },

  {
    path: 'client-documents',
    loadChildren: () => import('./client-documents/client-documents.module').then(m => m.ClientDocumentsModule),
    canActivate: [AuthGuard]
  },

  {
    path: 'rewards',
    loadChildren: () => import('./loyalty/rewards/rewards.module').then(m => m.RewardsModule),
    canActivate: [AuthGuard]
  },

  {
    path: 'webshop',
    loadChildren: () => import('./webshops/webshops.module').then(m => m.WebshopsModule),
    canActivate: [AuthGuard]
  },

  {
    path: 'redemption_reports',
    loadChildren: () => import('./redemption-report/redemption-report.module').then(m => m.RedemptionReportModule),
    canActivate: [AuthGuard]
  },

  {
    path: 'beta',
    loadChildren: () => import('./beta/beta.module').then(m => m.BetaModule),
    canActivate: [AuthGuard]
  },

  {
    path: 'feature',
    loadChildren: () => import('./beta/beta.module').then(m => m.BetaModule),
    canActivate: [AuthGuard]
  },

  {
    path: 'third-party-access',
    loadChildren: () => import('./third-party-access/third-party-access.module').then(m => m.ThirdPartyAccessModule),
    canActivate: [AuthGuard]
  },

  {
    path: 'referral-settings',
    loadChildren: () => import('./referral-settings/referral-settings.module').then(m => m.ReferralSettingsModule),
    canActivate: [AuthGuard]
  },

  {path: 'unauthorized', component: UnauthorizedComponent},

  {
    path: 'support',
    loadChildren: () => import('./support/support.module').then(m => m.SupportModule),
    canActivate: [AuthGuard]
  },

  {path: '**', component: Missing}
];
// export const appRoutingProviders: any[] = [];

// export const routing: ModuleWithProviders<any> = RouterModule.forRoot(appRoutes, {relativeLinkResolution: 'legacy'});

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // paramsInheritanceStrategy: 'always'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
