import {Component, OnDestroy, OnInit} from '@angular/core';
import {Partner} from '../partner/partner';
import {PartnerService} from '../partner/partner.service';
import {MerchantService} from '../merchant/merchant.service';
import {BetaService} from '../beta/beta.service';
import {Subscription} from 'rxjs';
import {AddonTypes} from '../addons/addon-types';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  partner: Partner = new Partner({});
  context: any = {};
  private subs = new Subscription();

  addonTypes = AddonTypes;

  constructor(private partnerService: PartnerService,
              private merchantService: MerchantService,
              public betaService: BetaService) {
  }

  ngOnInit() {
    this.subs.add(
        this.partnerService.currentPartner.subscribe(partner => {
          this.partner = partner;
        })
    );
    this.subs.add(
        this.merchantService.currentContext.subscribe(context => {
          this.context = context;
        })
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
