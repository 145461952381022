import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'percentage'})
export class PercentagePipe implements PipeTransform {

  constructor() {
  }

  transform(value: any): string {
    return Math.round(value * 100) + '%';
  }

}
