import {Component, OnInit} from '@angular/core';
import {faSort} from '@fortawesome/free-solid-svg-icons/faSort';
import {BestcustomersStatsService} from './bestcustomers.stats.service';
import {BestcustomerStats} from './bestcustomer.stats';
import {MerchantService} from '../../merchant/merchant.service';

@Component({
  selector: 'app-bestcustomers',
  templateUrl: './bestcustomers.component.html',
  styleUrls: ['./bestcustomers.component.scss']
})
export class BestcustomersComponent implements OnInit {

  faSort = faSort;
  bestCustomers: BestcustomerStats[];
  context = {};

  constructor(private bestCustomerStats: BestcustomersStatsService, private merchantService: MerchantService) { }

  ngOnInit() {
    this.merchantService.currentContext.subscribe(context => {
      this.context = context;

      setTimeout(() => {
        this.bestCustomerStats.fetchAll().subscribe((bestCustomers) => {
          this.bestCustomers = bestCustomers;
        });
      }, 100);
    });
  }
}
