import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {environment} from '../../../environments/environment';
import {MerchantService} from '../../merchant/merchant.service';
import {HttpClient} from '@angular/common/http';
import {delay, switchMap, takeUntil, tap} from 'rxjs/operators';
import {Observable} from 'rxjs/Observable';
import {Subject} from 'rxjs';

@Component({
  selector: 'stat-number',
  templateUrl: './statnumber.component.html',
  styleUrls: ['./statnumber.style.scss']
})
export class StatnumberComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();

  @Input() title: string;
  @Input() slug: string;

  current = 0;

  constructor(private http: HttpClient,
              private merchantService: MerchantService) {
  }

  ngOnInit() {
    this.merchantService.currentContext.pipe(
      takeUntil(this.ngUnsubscribe),
      switchMap(_ => this.loadSlugStats()),
      tap(slugStats => {
        this.current = slugStats.current;
      })
    ).subscribe();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private loadSlugStats(): Observable<{ current: number }> {
    return this.http.get<{ current: number }>(`${environment.api_base_url}stats/${this.slug}`);
  }

}
