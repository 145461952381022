import {Observable, throwError as observableThrowError} from 'rxjs';
import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {catchError, map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RewardsStatsService {

  URL: string = environment.api_base_url + 'stats/rewards';

  constructor(private http: HttpClient) {}

  fetchAll(timeRangeinDays: number): Observable<any> {
    const params = {};

    if (timeRangeinDays > 0) {
      params['range'] = timeRangeinDays;
    }

    return this.http.get(`${this.URL}`, {params: params})
        .pipe(
            // TODO does this work? Fix typing?
            map((res: any) => ({rewards: res.data, n: res.n})),
            catchError(this.handleError)
        );
  }

  private handleError(error: any) {
    // In a real world app, we might use a remote logging infrastructure
    // We'd also dig deeper into the error to get a better message
    const errMsg = (error.message) ? error.message :
      error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    console.error(errMsg); // log to console instead
    return observableThrowError(errMsg);
  }

}
