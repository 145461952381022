<ng-container *ngIf="!subscribeModal && !unsubscribeModal && !popupModal">
  <div class="row row-promo">
    <div class="col-12 col-md-6 col-text">
      <h1>Mehr Neukunden durch Empfehlungen</h1>

      <p>
        Nutze das Netzwerk deiner Kunden für dein Geschäft.
        Deine Kunden können unkompliziert und direkt dein Geschäft an ihre Freunde empfehlen. So erhältst du ohne Aufwand regelmäßig Neukunden.
      </p>

      <ul class="list-promo list-promo-checkmarks">
        <li>Konstant Neukunden gewinnen</li>
        <li>Kunden können unkompliziert dein Geschäft empfehlen</li>
        <li>Kaum Zeitaufwand dank der Automatisierung</li>
      </ul>

      <p>
        <button class="btn btn-primary btn-subscribe btn-lg mx-auto" (click)="onSubscribe.emit()">Hier aktivieren</button>
      </p>
    </div>

    <div class="col-12 col-md-6 col-img">
      <img class="img-fluid corners-rounded" src="assets/img/referral/promotion/customer-network.jpg">
    </div>
  </div>

  <div class="row row-promo background-colored corners-rounded">
    <div class="col-12 col-md-6 col-img">
      <img class="img-fluid corners-rounded" src="assets/img/referral/promotion/price.jpg">
    </div>

    <div class="col-12 col-md-6 col-text">

      <p>
        Gewinne stetig neue Kunden ohne kompliziertes Marketing.
      </p>

      <ul class="list-promo list-promo-checkmarks">
        <li>Keine Laufzeit - deaktiviere das Feature jederzeit</li>
        <li>Ein Festpreis - keine versteckten Kosten</li>
        <li>Minimale Kosten für maximalen Erfolg</li>
      </ul>
    </div>
  </div>

  <div class="row row-promo">
    <div class="col-12 col-md-6 col-img">
      <img class="img-fluid corners-rounded" src="assets/img/referral/promotion/chat-messages.jpg">
    </div>

    <div class="col-12 col-md-6 col-text">
      <h1>Lass deine Kunden für dich werben</h1>

      <p>
        Deine Kunden brauchen nur die E-Mail Adresse ihrer Freunde einzugeben, um dein Geschäft zu empfehlen.
      </p>

      <ul class="list-promo list-promo-checkmarks">
        <li>Unkompliziertes Weiterempfehlen über die E-Mail Adresse deiner Kunden</li>
        <li>Automatisierte Synchronisierung mit dem Mankido Konto</li>
        <li>Punkte werden Neukunden direkt beim ersten Kauf gutgeschrieben</li>
      </ul>

      <p>
        <button class="btn btn-primary btn-subscribe btn-lg mx-auto" (click)="onSubscribe.emit()">Hier aktivieren</button>
      </p>
    </div>
  </div>

  <div class="row row-promo">
    <div class="col-12 col-md-6 col-text">
      <h1>Lege die Anzahl der Punkte selbst fest</h1>

      <ul class="list-promo list-promo-checkmarks">
        <li>Separate Punktevergabe für Neu- und Stammkunden</li>
        <li>Entscheide selbst, was dir eine Empfehlung wert ist</li>
      </ul>

      <p>
        <button class="btn btn-primary btn-subscribe btn-lg mx-auto" (click)="onSubscribe.emit()">Hier aktivieren</button>
      </p>
    </div>

    <div class="col-12 col-md-6 col-img">
      <img class="img-fluid corners-rounded" src="assets/img/referral/promotion/rewards.jpg">
    </div>
  </div>
</ng-container>

<ng-container *ngIf="subscribeModal">
  <div class="row row-promo row-promo-modal">
    <div class="col-12 col-md-6 col-img">
      <img class="img-fluid corners-rounded" src="assets/img/referral/promotion/modal-price.jpg">
    </div>

    <div class="col-12 col-md-6 col-text">

      <h1>Profitiere auch du von Empfehlungs&shy;marketing</h1>

      <p>
        Gewinne stetig neue Kunden ohne kompliziertes Marketing.
      </p>

      <ul class="list-promo list-promo-checkmarks">
        <li>Keine Laufzeit - deaktiviere das Feature jederzeit</li>
        <li>Ein Festpreis - keine versteckten Kosten</li>
        <li>Minimale Kosten für maximalen Erfolg</li>
      </ul>

      <button class="btn btn-success btn-lg mx-auto" (click)="onSubscribe.emit()">Jetzt aktivieren</button>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="unsubscribeModal">
  <div class="row row-promo row-promo-modal">
    <div class="col-12 col-md-6 col-img">
      <img class="img-fluid corners-rounded" src="assets/img/referral/promotion/modal-price.jpg">
    </div>

    <div class="col-12 col-md-6 col-text">

      <h1>Du möchtest keine neuen Kunden mit Mankido gewinnen?</h1>

      <p>
        Kein Problem, deaktiviere das Feature hier jederzeit.
      </p>

      <button class="btn btn-danger btn-lg mx-auto" (click)="onSubscribe.emit()">Jetzt beenden</button>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="popupModal">
  <div class="row row-promo row-promo-modal">
    <div class="col-12 col-md-6 col-text">
      <div class="badge badge-pill badge-promo">Neu</div>
      <h1>Lass deine Kunden für dich werben</h1>

      <p>
        Gewinne stetig neue Kunden ohne kompliziertes Marketing.
      </p>

      <ul class="list-promo list-promo-checkmarks">
        <li>Keine Laufzeit - deaktiviere das Feature jederzeit</li>
        <li>Ein Festpreis - keine versteckten Kosten</li>
        <li>Minimale Kosten für maximalen Erfolg</li>
      </ul>

      <button class="btn btn-primary btn-subscribe btn-lg w-fit-content" style="width: fit-content;" (click)="onSubscribe.emit()">Mehr erfahren</button>
    </div>

    <div class="col-12 col-md-6 col-img">
      <img class="img-fluid corners-rounded" src="assets/img/referral/promotion/modal-price.jpg">
    </div>
  </div>
</ng-container>

