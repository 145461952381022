import {Component, OnDestroy, OnInit} from '@angular/core';
import {Merchant} from './merchant/merchant';
import {MerchantService} from './merchant/merchant.service';
import {AuthService} from './auth';
import {AddonSubscriptionService} from './addons/addon-subscription.service';
import {AddonTypes} from './addons/addon-types';
import {takeUntil, tap} from 'rxjs/operators';
import {faArrowRight} from '@fortawesome/free-solid-svg-icons';
import {Subject} from 'rxjs';
import {ReleaseMonitorService} from './release-monitor/release-monitor.service';

@Component({
  selector: 'app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();

  merchant: Merchant;
  featureObtainReviewActive: boolean = true;
  featureReferralActive: boolean = true;
  faArrowRight = faArrowRight;

  constructor(private authService: AuthService,
              private merchantService: MerchantService,
              private addonSubscriptionService: AddonSubscriptionService,
              private releaseMonitorService: ReleaseMonitorService) {
  }

  ngOnInit() {
    // skip AuthService initialization when using token login
    if (!window.location.pathname.startsWith('/login/ey')) {
      this.authService.init();
    }

    this.merchantService.currentMerchant.pipe(
      takeUntil(this.ngUnsubscribe),
      tap((merchant: Merchant) => this.merchant = merchant)
    ).subscribe();

    this.addonSubscriptionService.hasActiveSubscriptionFor(AddonTypes.OBTAIN_REVIEW).pipe(
      takeUntil(this.ngUnsubscribe),
      tap((hasActive: boolean) => this.featureObtainReviewActive = hasActive)
    ).subscribe();

    this.addonSubscriptionService.hasActiveSubscriptionFor(AddonTypes.REFERRAL).pipe(
      takeUntil(this.ngUnsubscribe),
      tap((hasActive: boolean) => this.featureReferralActive = hasActive)
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
