import {Asset} from '../assets/asset';
import {Partner} from '../partner/partner';

export class Deal {
  static deal_types: string[] = ['voucher', 'percent', 'xfory', 'free', 'largeness', 'fixed', 'special_points_event'];

  id: number;
  type: string;
  asset_id: number;
  title: string;
  description: string;
  terms: string;
  deal_type: string;
  old_price: number;
  new_price: number;
  percent_amount: number;
  amount_x: number;
  amount_y: number;
  fixed_amount: number;
  old_size: string;
  new_size: string;
  duration_in_days: number;

  asset: Asset;
  partner: Partner;

  starts_at: Date;
  ends_at: Date;

  created_at: Date;

  constructor(v: Partial<Deal> = {}) {
    this.id = v.id;
    this.type = v.type;
    this.asset_id = v.asset_id;
    this.title = v.title;
    this.description = v.description;
    this.terms = v.terms;
    this.deal_type = v.deal_type;
    this.old_price = v.old_price;
    this.new_price = v.new_price;
    this.percent_amount = v.percent_amount;
    this.amount_x = v.amount_x;
    this.amount_y = v.amount_y;
    this.fixed_amount = v.fixed_amount;
    this.old_size = v.old_size;
    this.new_size = v.new_size;
    this.duration_in_days = v.duration_in_days;

    this.asset = v.asset ? new Asset(v.asset) : null;
    this.partner = v.partner ? new Partner(v.partner) : null;

    this.ends_at = v.ends_at ? new Date(v.ends_at) : null;
    this.starts_at = v.starts_at ? new Date(v.starts_at) : null;
    this.created_at = v.created_at ? new Date(v.created_at) : null;
  }

  static valueToString(deal: Deal): string {
    switch (deal.deal_type) {
      case 'voucher':
        return `${deal.new_price} cent statt ${deal.new_price} cent`;
      case 'percent':
        return `${deal.percent_amount}%`;
      case 'xfory':
        return `${deal.amount_x} für ${deal.amount_y}`;
      case 'fixed':
        return `${deal.fixed_amount} cent`;
      case 'free':
        return ``;
      case 'largeness':
        return `${deal.new_size} statt ${deal.old_size}`;
      default:
        return '';
    }
  }

  valid(): boolean {
    if (!this.title || !this.description || Deal.deal_types.indexOf(this.deal_type) === -1) {
      return false;
    }

    if (this.deal_type === 'special_points_event') {
      return true;
    }

    if (!this.asset || !(this.asset.id > -1)) {
      return false;
    }

    if (this.type !== 'Teaser' && (this.starts_at.getTime() >= this.ends_at.getTime() || this.ends_at.getTime() <= new Date().getTime())) {
      return false;
    }

    switch (this.deal_type) {
      case 'voucher':
        return (this.new_price > 0 && this.old_price > 0);
      case 'percent':
        return (this.percent_amount > 0);
      case 'xfory':
        return (this.amount_x > 0 && this.amount_y > 0);
      case 'fixed':
        return (this.fixed_amount > 0);
      case 'free':
        return true;
      case 'largeness':
        return (this.new_size && this.new_size.length > 0 && this.old_size && this.old_size.length > 0);
      default:
        return true;
    }
  }

  public asRequest(): Partial<Deal> {
    return {
      id: this.id,
      title: this.title,
      description: this.description,
      terms: this.terms,
      deal_type: this.deal_type,
      percent_amount: this.percent_amount,
      amount_x: this.amount_x,
      amount_y: this.amount_y,
      fixed_amount: this.fixed_amount,
      old_price: this.old_price,
      new_price: this.new_price,
      old_size: this.old_size,
      new_size: this.new_size,
      starts_at: this.starts_at,
      ends_at: this.ends_at,
      duration_in_days: this.duration_in_days,
      asset_id: this.asset ? this.asset.id : null,
      type: this.type,
    };
  }
}
