import {Component, OnInit} from '@angular/core';
import {RewardsStatsService} from './rewards.stats.service';
import {RewardStats} from './reward.stats';
import {faSort} from '@fortawesome/free-solid-svg-icons/faSort';
import {MerchantService} from '../../merchant/merchant.service';

@Component({
  selector: 'app-dashboard-rewards',
  templateUrl: './rewards.component.html',
  styleUrls: ['./rewards.component.scss']
})
export class DashboardRewardsComponent implements OnInit {
  faSort = faSort;
  Object = Object;
  timeRangeinDays = '0';
  timeRanges = {
    '30': 'Letzte 30 Tage',
    '90': 'Letzte 90 Tage',
    '0': 'Gesamt'
  };

  context: any = {};
  rewards: RewardStats[];
  n: number;
  sum: number;

  pieChartLabels: string[] = ['Download Sales', 'In-Store Sales', 'Mail Sales'];
  pieChartData: number[] = [30, 20, 15, 10, 5];

  new_customer = '#82d581';
  walk_in_customer = '#31aedd';
  vip = '#926ec3';
  orange = '#E0AE68';
  red = '#D58181';
  colors  = [{backgroundColor: [
      this.vip,
      this.walk_in_customer,
      this.new_customer,
      this.orange,
      this.red], borderWidth: [0, 0, 0, 0, 0, 0]}];

  constructor(private rewardsStatsService: RewardsStatsService, private merchantService: MerchantService) { }

  ngOnInit() {
    this.merchantService.currentContext.subscribe(context => {
      this.context = context;

      setTimeout(() => {
        this.loadData();
      }, 200);
    });
  }

  setTimeRange(range): void {
    this.timeRangeinDays = range;
    this.loadData();
  }

  private loadData() {
    this.rewardsStatsService.fetchAll(+this.timeRangeinDays).subscribe((res) => {
      this.rewards = res.rewards;

      this.rewards.sort((a, b) => b.redemptions - a.redemptions);

      const lim = Math.min(5, this.rewards.length);

      this.pieChartData = this.rewards.slice(0, lim).map( el => el.redemptions);
      this.sum = this.rewards.reduce((accumulator, reward) => accumulator + reward.redemptions, 0);

      if (lim < this.rewards.length) {
        this.pieChartData.push(this.rewards.slice(lim + 1).reduce((accumulator, reward) => accumulator + reward.redemptions, 0));
      }

      this.rewards = this.rewards.slice(0, lim);

      this.n = res.n;
    });
  }
}
