import {Observable, throwError as observableThrowError} from 'rxjs';
import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {SegmentData} from './segmentdata';
import {catchError, map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SegmentationService {

  SEGMENTS_URL: string = environment.api_base_url + 'stats/segments';

  constructor(private http: HttpClient) {
  }

  fetchAll(): Observable<Map<string, SegmentData>> {
    return this.http.get(`${this.SEGMENTS_URL}`).pipe(
        map(this.extractData),
        catchError(this.handleError)
    );
  }


  private extractData(res: any) {
    const segments: Map<string, SegmentData> = new Map();
    // TODO fix this! Does this work?
    const data = res.data;
    data.forEach(el => {
      segments.set(el.segment, el.data);
    });

    return segments;
  }

  private handleError(error: any) {
    // In a real world app, we might use a remote logging infrastructure
    // We'd also dig deeper into the error to get a better message
    const errMsg = (error.message) ? error.message :
        error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    console.error(errMsg); // log to console instead
    return observableThrowError(errMsg);
  }

}
