<app-navbar *ngIf="merchant?.id"></app-navbar>

<ng-container *ngIf="merchant?.id">
  <div class="banner banner-obtain-review" [routerLink]="['automation', 'obtain_review']" *ngIf="!featureObtainReviewActive && featureReferralActive">
    <img src="assets/img/obtain-review/google-logo.svg">
    Neues Feature: Mehr Google Bewertungen mit Mankido. <span>Mehr erfahren</span>&nbsp;<i class="fa fa-arrow-right"></i>
  </div>
  <div class="banner banner-referral" [routerLink]="['/referral-settings']" *ngIf="!featureReferralActive">
    <div class="badge badge-success badge-promo">Neu</div>
    <span>Neues Feature:</span> Deine Kunden empfehlen sich gegenseitig
    <fa-icon [icon]="faArrowRight"></fa-icon>
  </div>
</ng-container>

<div class="container">
  <notifications></notifications>
  <router-outlet></router-outlet>
</div>
<modal></modal>
<!-- <progressbar></progressbar> -->
