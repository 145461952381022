<ng-template #modalContent let-modal>
  <div class="modal-body">

    <div class="w-100" style="height: 1.5em;">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
    </div>
    <app-obtain-review-promotion *ngIf="addonType === 'obtain_review'" [popupModal]="true" (onSubscribe)="modal.close('more')"></app-obtain-review-promotion>
    <app-referral-promotion *ngIf="addonType === 'referral'" [popupModal]="true" (onSubscribe)="modal.close('more')"></app-referral-promotion>
  </div>
</ng-template>
