import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-obtain-review-promotion',
  templateUrl: './obtain-review-promotion.component.html',
  styleUrls: ['./obtain-review-promotion.component.scss']
})
export class ObtainReviewPromotionComponent implements OnInit {

  @Input()
  subscribeModal: boolean;

  @Input()
  unsubscribeModal: boolean;

  @Input()
  popupModal: boolean;

  @Output()
  onSubscribe = new EventEmitter<void>();

  constructor() {
  }

  ngOnInit(): void {
  }

  subscribe() {
    this.onSubscribe.next();
  }

}
