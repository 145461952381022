<div class="row mb-3">
  <div class="col col-xl-10 offset-xl-1">
    <h2 class="text-center mt-3 mb-2">Prämien</h2>
    <div class="row">
      <div class="col">
        <div class="card">
          <table class="table card-table">
            <thead>
            <tr>
              <th colspan="4" ngbDropdown>
                <div ngbDropdown placement="bottom-right" class="d-inline-block float-right">
                  <button class="time-range-picker" id="dropdownBasic1" ngbDropdownToggle>{{timeRanges[timeRangeinDays]}}</button>
                  <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-menu-right">
                    <button class="dropdown-item" *ngFor="let range of Object.keys(timeRanges)" (click)="setTimeRange(range)">
                      {{timeRanges[range]}}
                    </button>
                  </div>
                </div>
                Übersicht Top Prämien
              </th>
            </tr>
            </thead>
          </table>
          <div class="row">
            <div class="col col-4 p-2 flex-column justify-content-center chart-wrapper"  *ngIf="sum > 0">
              <canvas baseChart
                      [data]="pieChartData"
                      [labels]="pieChartLabels"
                      [colors]="colors"
                      [chartType]="'doughnut'"
                      [legend]="false"
                      [options]="{cutoutPercentage: 65, tooltips: {enabled: false}, responsive: true, aspectRatio: 1}"
                      style="margin-left: 15px;"></canvas>
            </div>
            <div class="col col-12 col-md-8" [class.offset-2]="sum === 0 || !rewards">
              <table class="table card-table">
                <tbody>
                <ng-container *ngIf="rewards && sum  > 0">
                  <tr *ngFor="let reward of rewards; let i = index">
                    <td class="d-flex align-items-center">
                      <span class="dot" [style.background-color]="colors[0].backgroundColor[i]"></span>
                      <b class="points">{{reward.points}}P</b>
                      <p class="m-0 d-inline-block">
                        {{reward.title}}
                      </p>
                    </td>
                    <td>
                      <span *ngIf="sum > 0">
                        {{(reward.redemptions / sum) | percentage }}
                      </span>
                    </td>
                  </tr>
                </ng-container>
                <tr *ngIf="!rewards">
                  <td colspan="4">
                    <div class="text-center" style="height: 200px; line-height: 200px;">Daten werden geladen...</div>
                  </td>
                </tr>
                <tr *ngIf="rewards && sum === 0">
                  <td colspan="4">
                    <div class="text-center" style="height: 200px; line-height: 200px;">Noch keine Daten verfügbar</div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
